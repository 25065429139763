import { registerRootStore } from "mobx-keystone";
import React from "react";
import ActivityStore from "./ActivityStore";
import AssetStore from "./AssetStore";
import AuthStore from "./AuthStore";
import CLTAssumptionsStore from "./CLTAssumptionsStore";
import CLTFileStore from "./CLTFileStore";
import ClusterInputSheetsStatusStore from "./ClusterInputSheetsStatusStore";
import CommentStore from "./CommentStore";
import DefineSheetStore from "./DefineSheetStore";
import DeliveryInfoStore from "./DeliveryInfoStore";
import ForexStore from "./ForexStore";
import FormulationStore from "./FormulationStore";
import InputSheetStore from "./InputSheetStore";
import OutputSheetStore from "./OutputSheetStore";
import ProjectFolderStore from "./ProjectFolderStore";
import ProjectStore from "./ProjectStore";
import ProjectVersionStore from "./ProjectVersionStore";
import RMFormulationStore from "./RMFormulationStore";
import RMPriceAssumptionStore from "./RMPriceAssumptionStore";
import RequirementStore from "./RequirementStore";
import RootStore from "./RootStore";
import TenderOutcomeStore from "./TenderOutcomeStore";
import UserStore from "./UserStore";

const StoreContext = React.createContext<RootStore>({} as RootStore);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

function createRootStore() {
  const activities = new ActivityStore({});
  const auth = new AuthStore({});
  const assets = new AssetStore({});
  const cltAssumptions = new CLTAssumptionsStore({});
  const cltFiles = new CLTFileStore({});
  const clusterInputSheetsStatus = new ClusterInputSheetsStatusStore({});
  const comments = new CommentStore({});
  const defineSheets = new DefineSheetStore({});
  const deliveryInfo = new DeliveryInfoStore({});
  const forex = new ForexStore({});
  const formulations = new FormulationStore({});
  const inputSheets = new InputSheetStore({});
  const outputSheets = new OutputSheetStore({});
  const projects = new ProjectStore({});
  const projectFolders = new ProjectFolderStore({});
  const projectVersions = new ProjectVersionStore({});
  const requirements = new RequirementStore({});
  const rmFormulations = new RMFormulationStore({});
  const rmPriceAssumptions = new RMPriceAssumptionStore({});
  const tenderOutcomes = new TenderOutcomeStore({});
  const users = new UserStore({});
  const rootStore = new RootStore({
    activities,
    auth,
    assets,
    cltAssumptions,
    cltFiles,
    clusterInputSheetsStatus,
    comments,
    defineSheets,
    deliveryInfo,
    forex,
    inputSheets,
    outputSheets,
    formulations,
    projectFolders,
    projectVersions,
    projects,
    requirements,
    rmFormulations,
    rmPriceAssumptions,
    tenderOutcomes,
    users,
  });

  registerRootStore(rootStore);

  return rootStore;
}

export { createRootStore, RootStore, StoreContext, StoreProvider, useStore };
