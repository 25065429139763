import { FormatColorFill } from "@material-ui/icons";
import api from "api";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { CsvFile, CsvFileSet } from "pages/Workspace/types";
import React, { useCallback, useMemo } from "react";
import type { ButtonProps } from "react-bootstrap";
import { useStore } from "store";
import { Toast } from "utils";

type Props = ButtonProps & {
  files?: CsvFileSet[];
};

const AutofillFlatRateButton: React.FC<Props> = ({
  files,
  variant = "clt-files",
  size = "thin",
  ...props
}) => {
  const project = useProject();
  const store = useStore();
  const currentUser = store.auth.current;

  const file = useMemo(() => {
    if (files === undefined || project === undefined) {
      return undefined;
    }

    const fileSet = files.find((f) =>
      "cluster" in f[0].sheet
        ? f[0].sheet.cluster === project.activeCluster
        : false
    );
    if (fileSet === undefined) return undefined;
    return fileSet[0];
  }, [files, project, project?.activeCluster]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasEditPermission =
    !!file?.editable &&
    currentUser &&
    (currentUser.id === project?.creator ||
      currentUser.isAnalyst ||
      currentUser.isAdmin ||
      project?.adminUsers.includes(currentUser.id));

  const performAutofill = useCallback(async (file: CsvFile) => {
    try {
      const response = await api.inputSheets.autofill(file.sheet.id);
      file.sheet.update(response);
    } catch (e) {
      throw e;
    }
  }, []);

  const handleAutofillFlatRateClick = useCallback(async () => {
    if (!project || !file) return;

    project.setLoading();

    try {
      await performAutofill(file);

      if (file.sheet.fileType === "oh_full_pack_mfg") {
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type__in: ["oh_full_pack_mfg", "oh_marg_pack_mfg"],
        });
      } else if (file.sheet.fileType === "oh_full_logs") {
        await store.inputSheets.list(project.id, undefined, undefined, {
          file_type__in: ["oh_full_logs", "oh_marg_logs"],
        });
      }

      Toast.success("Flat rates successfully filled.");
    } catch (e) {
      Toast.danger("Unable to fill flat rates.");
      return;
    } finally {
      project.setNotLoading();
    }
  }, [file, performAutofill, project, store.inputSheets]);

  return (
    <>
      <Button
        variant={variant}
        size={size}
        onClick={handleAutofillFlatRateClick}
        disabled={!hasEditPermission || project?.loading || project?.isDirty}
        {...props}
      >
        <FormatColorFill />
        Autofill Flat Rate
      </Button>
    </>
  );
};

export default observer(AutofillFlatRateButton);
