import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useMemo } from "react";
import { CellRendererProps, Cell as RDGCell } from "react-data-grid";
import { IRow } from "../../csv/types";
import { DataGridContext, RangeSelectionContext } from "./contexts";
import { Position, PositionData } from "./types";
import { emptyPositionData, getPositionData, isPositionInRange } from "./utils";

function Cell<R extends IRow, SR>(
  props: CellRendererProps<R, SR>,
  ref: React.Ref<HTMLDivElement>
) {
  const { className, rowIdx, row, column, selectCell } = props;
  const { columns, range, setRange, setSelectionType, selectionType } =
    useContext(RangeSelectionContext);
  const { errors, commentIdentifiers, rowIdentifier, onCellSelect } =
    useContext(DataGridContext);
  const isRowHeader = column.idx === 0;
  const cellSelectionType = isRowHeader ? "row" : "cell";

  // <-- Cell and row selection //

  const cellPosition: Position = useMemo(
    () => ({
      idx: column.idx,
      rowIdx,
    }),
    [column.idx, rowIdx]
  );
  const start: Position = useMemo(
    () => ({
      idx: 1,
      rowIdx,
    }),
    [rowIdx]
  );
  const end: Position = useMemo(
    () => ({
      idx: columns.length - 1,
      rowIdx,
    }),
    [columns.length, rowIdx]
  );

  const cellSelectionMetaData: PositionData = useMemo(
    () => (range ? getPositionData(cellPosition, range) : emptyPositionData),
    [cellPosition, range]
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!setRange) {
        return;
      }
      if (e.buttons === 1 && selectionType === cellSelectionType) {
        setRange((prevRange) => {
          if (prevRange) {
            const next = {
              ...prevRange,
              end: isRowHeader ? end : cellPosition,
            };
            return next;
          }
          return prevRange;
        });
      }
    },
    [cellPosition, cellSelectionType, end, isRowHeader, selectionType, setRange]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setSelectionType?.(cellSelectionType);
      onCellSelect?.(row, columns[column.idx]);

      if (isRowHeader) {
        selectCell(start, false);
        setRange?.({ start, end });
      } else {
        selectCell(cellPosition, false);
        if (
          e.buttons === 1 || // Left-click
          !range ||
          !isPositionInRange(cellPosition, range) // Right-click outside existing range
        ) {
          setRange?.({ start: cellPosition, end: cellPosition });
        }
      }
    },
    [
      cellPosition,
      cellSelectionType,
      column.idx,
      columns,
      end,
      isRowHeader,
      onCellSelect,
      range,
      row,
      selectCell,
      setRange,
      setSelectionType,
      start,
    ]
  );

  // Cell and row selection --> //

  const [errorCode, errorMessage] = useMemo(() => {
    if (errors?.[`${column.key}-${row.id}`]) {
      return errors[`${column.key}-${row.id}`].split(":", 2);
    }
    return ["", ""];
  }, [column.key, errors, row]);

  const updatedClassNames = useMemo(
    () =>
      clsx(
        className,
        { selected: cellSelectionMetaData.isInside },
        { selectedTop: cellSelectionMetaData.isTop },
        { selectedBottom: cellSelectionMetaData.isBottom },
        { selectedLeft: cellSelectionMetaData.isLeft },
        { selectedRight: cellSelectionMetaData.isRight },
        { hasError: errorCode === "E" },
        { hasWarning: errorCode === "W" },
        {
          hasComments:
            commentIdentifiers?.[`${column.key}-${row[rowIdentifier]}`],
        }
      ),
    [
      cellSelectionMetaData.isBottom,
      cellSelectionMetaData.isInside,
      cellSelectionMetaData.isLeft,
      cellSelectionMetaData.isRight,
      cellSelectionMetaData.isTop,
      className,
      column.key,
      commentIdentifiers,
      errorCode,
      row,
      rowIdentifier,
    ]
  );

  return (
    <RDGCell<R, SR>
      {...props}
      ref={ref}
      title={errorMessage}
      className={updatedClassNames}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
    />
  );
}

export default observer(Cell, { forwardRef: true });
