import clsx from "clsx";
import { getNumberFormatter } from "../../../components/DataGrid/NumberFormatter";
import { ColumnExtraOptions } from "../../../components/DataGrid/types";
import { IRow } from "../../../csv/types";
import { scaledRem } from "../../../utils";

export const givenCell: ColumnExtraOptions<IRow> = {
  editable: false,
  cellClass(row) {
    if (!row.id) {
      return undefined;
    }
    return "cell-given";
  },
  frozen: true,
  width: scaledRem(120),
  resizable: false,
};

export function getGivenCell(
  frozen: boolean = false
): ColumnExtraOptions<IRow> {
  return {
    editable: false,
    cellClass(row) {
      if (!row.id) {
        return undefined;
      }
      return "cell-given";
    },
    frozen: frozen,
    width: scaledRem(120),
    resizable: false,
  };
}

export function getInputCell(sheetEditable: boolean): ColumnExtraOptions<IRow> {
  return {
    editable(row) {
      if (!row.id) {
        return false;
      }
      if (!sheetEditable) {
        return false;
      }
      return true;
    },
    frozen: false,
    width: scaledRem(120),
    cellClass(row) {
      if (!row.id) {
        return undefined;
      }
      if (!sheetEditable) {
        return "cell-readonly";
      }
      return "cell-input";
    },
  };
}

export function getGivenNumberCell(
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 4,
  percentage: boolean = false,
  frozen: boolean = false
): ColumnExtraOptions<IRow> {
  const inputCell = getGivenCell(frozen);
  const NumberFormatter = getNumberFormatter(
    minimumFractionDigits,
    maximumFractionDigits,
    percentage
  );
  return {
    ...inputCell,
    cellClass(row) {
      return clsx((inputCell.cellClass! as Function)(row), "cell-numeric");
    },
    formatter: NumberFormatter,
  };
}

export function getNumberCell(
  sheetEditable: boolean,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 4,
  percentage: boolean = false
): ColumnExtraOptions<IRow> {
  const inputCell = getInputCell(sheetEditable);
  const NumberFormatter = getNumberFormatter(
    minimumFractionDigits,
    maximumFractionDigits,
    percentage
  );
  return {
    ...inputCell,
    cellClass(row) {
      return clsx((inputCell.cellClass! as Function)(row), "cell-numeric");
    },
    formatter: NumberFormatter,
  };
}

export function clusterRestrictedCellGenerator(
  cellType: ColumnExtraOptions<IRow>,
  key: string,
  clusterName?: string,
  sheetEditable?: boolean
): ColumnExtraOptions<IRow> {
  return {
    ...cellType,
    editable: (row: IRow) => {
      return row[key] === clusterName;
    },
    cellClass: (row: IRow) => {
      if (!sheetEditable) {
        return "cell-readonly";
      }
      return row[key] === clusterName
        ? (cellType.cellClass as Function)(row)
        : "cell-input cell-disallowed";
    },
  };
}

export function editableCellGenerator(
  cellType: ColumnExtraOptions<IRow>,
  editable: boolean,
  sheetEditable?: boolean
): ColumnExtraOptions<IRow> {
  return {
    ...cellType,
    editable,
    cellClass: (row: IRow) => {
      if (!sheetEditable) {
        return "cell-readonly";
      }
      return editable
        ? (cellType.cellClass as Function)(row)
        : "cell-input cell-disallowed";
    },
  };
}

export const idCell: ColumnExtraOptions<IRow> = {
  editable: false,
  resizable: false,
  cellClass: undefined,
};

export const activeColumn: ColumnExtraOptions<IRow> = {
  ...givenCell,
  frozen: true,
  maxWidth: scaledRem(70),
  cellClass(row) {
    if (row.id && row.active?.toString().toLowerCase() !== "true") {
      return "cell-input";
    }
    return (givenCell.cellClass! as Function)(row);
  },
  formatter: ({ row }) => {
    if (!row.id) {
      return null;
    }
    return (
      <>
        {row.active?.toString().toLowerCase() === "true" ? "Active" : "Unused"}
      </>
    );
  },
};

export function getActiveColumn(
  frozen: boolean = false
): ColumnExtraOptions<IRow> {
  return {
    ...givenCell,
    frozen: frozen ? true : false,
    maxWidth: scaledRem(70),
    cellClass(row) {
      if (row.id && row.active?.toString().toLowerCase() !== "true") {
        return "cell-input";
      }
      return (givenCell.cellClass! as Function)(row);
    },
    formatter: ({ row }) => {
      if (!row.id) {
        return null;
      }
      return (
        <>
          {row.active?.toString().toLowerCase() === "true"
            ? "Active"
            : "Unused"}
        </>
      );
    },
  };
}

export function getStatusColumn(
  frozen: boolean = false
): ColumnExtraOptions<IRow> {
  return {
    ...givenCell,
    name: "Flat\nRates",
    frozen: frozen ? true : false,
    maxWidth: scaledRem(90),
    cellClass(row) {
      if (row.id && row.flat_rate_status?.toString().toLowerCase() !== "true") {
        return "cell-input";
      }
      return (givenCell.cellClass! as Function)(row);
    },
    formatter: ({ row }) => {
      if (!row.id) {
        return null;
      }
      return (
        <>
          {row.flat_rate_status?.toString().toLowerCase() === "true"
            ? "Filled"
            : "None"}
        </>
      );
    },
  };
}
