import { AutofillRMPAButton } from "components/AutofillButtons";
import localforage from "localforage";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Column } from "react-data-grid";
import { Toast } from "utils";
import { APICountryListSearchParams } from "../../api/countries";
import { APIDefineSheetFileType } from "../../api/defineSheets";
import { AutofillForexButton } from "../../components/AutofillButtons";
import dropdownEditorFactory from "../../components/DataGrid/DropdownEditor";
import NumberFormatter from "../../components/DataGrid/NumberFormatter";
import SelectCellFormatter from "../../components/DataGrid/SelectCellFormatter";
import { ColumnExtraOptions } from "../../components/DataGrid/types";
import Loading from "../../components/Loading";
import { mappingPriceAssumptions } from "../../csv/mappings/define";
import { IRow } from "../../csv/types";
import { useStore } from "../../store";
import DefineSheet from "../../store/models/DefineSheet";
import { dismissProjectLock, dismissProjectUnlock } from "../../utils/confirm";
import { schemaCostingSheet } from "../OutputSection/schema";
import WorkArea from "../WorkArea";
import { useProject } from "../Workspace/hooks";
import { TabList } from "../Workspace/types";
import DeliveryInfoTab from "./DeliveryInfoTab";
import ForexCSVSheet from "./ForexCSVSheet";
import FormulationsTab from "./FormulationsTab";
import GenerateCostSheetButton from "./GenerateCostSheetButton";
import KeyAssumptionsTab from "./KeyAssumptionsTab";
import RMFormulationsTab from "./RMFormulationsTab";
import RequirementsTab from "./RequirementsTab";

type Props = {};

const DefineSection: React.FC<Props> = () => {
  const store = useStore();
  const project = useProject();
  const currentUser = store.auth.current;
  const hasEditPermission = !!(
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      project?.adminUsers.includes(currentUser.id))
  );
  const editable = hasEditPermission && !project?.isLocked;

  const hasRmPriceEditPermissions = !!(
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.isAnalyst ||
      project?.adminUsers.includes(currentUser.id) ||
      project?.rmPriceUsers.includes(currentUser.id))
  );
  const rmPriceEditable =
    hasRmPriceEditPermissions && !project?.isLocked && !project?.rmpaSubmitted;

  useEffect(() => {
    (async () => {
      if (project) {
        const countryParams: APICountryListSearchParams = {
          project_id: project.id.toString(),
        };
        await store.assets.listCountries(undefined, undefined, countryParams);
      }
    })();
  }, [project, store.assets]);

  useEffect(() => {
    (async () => {
      if (project) {
        const cltLocked =
          currentUser?.analystCluster &&
          project.lockedClusters.includes(currentUser.analystCluster);
        const projectNotifiedKey = `notifiedProjectLocked-${project.id}-${currentUser?.id}`;
        const notifiedProjectLocked =
          (await localforage.getItem(projectNotifiedKey)) ??
          (await localforage.setItem(projectNotifiedKey, false));

        if (project.isLocked || cltLocked) {
          if (!notifiedProjectLocked) {
            if (await dismissProjectLock()) {
              await localforage.setItem(
                projectNotifiedKey,
                project.isLocked || cltLocked
              );
            }
          }
        } else {
          if (notifiedProjectLocked) {
            if (await dismissProjectUnlock()) {
              await localforage.setItem(
                projectNotifiedKey,
                project.isLocked || cltLocked
              );
            }
          }
        }
      }
    })();
  }, [project, currentUser]);

  const sheets = project
    ? store.defineSheets.ofProject(project.id)
    : new Map<APIDefineSheetFileType, DefineSheet[]>();

  const forexCsvFiles =
    sheets.get("forex")?.map((sheet) => [
      {
        id: `forex-${sheet.id}`,
        sheet,
        downloadable: true,
        editable: editable,
        insertable: editable,
        updatable: editable,
        rowIdentifier: "currency_code",
        errorFn: (row: IRow, column: Column<IRow>) => {
          if (row[column.key] === "") {
            return "E002";
          }
          if (column.key === "1usd=" || column.key === "1euro=") {
            if (
              isNaN(parseFloat(row[column.key] as string)) ||
              !isFinite(row[column.key] as number)
            ) {
              return "E003";
            }
          }
          return "";
        },
        schema: (key: string, sheetEditable?: boolean) => {
          const options: Record<string, ColumnExtraOptions<IRow>> = {
            currency_code: {
              editable: false,
              cellClass: "cell-readonly",
            },
            currency: {
              editable: false,
              cellClass: "cell-readonly",
            },
            "1usd=": {
              editable: sheetEditable ?? true,
              cellClass:
                sheetEditable ?? true
                  ? "cell-numeric"
                  : "cell-readonly cell-numeric",
            },
            "1euro=": {
              editable: sheetEditable ?? true,
              cellClass:
                sheetEditable ?? true
                  ? "cell-numeric"
                  : "cell-readonly cell-numeric",
            },
          };
          return options[key] || {};
        },
      },
    ]) || [];
  const rmpaCsvFiles =
    sheets.get("rm_price_assumptions")?.map((sheet) => [
      {
        id: `priceAssumptions-${sheet.id}`,
        sheet,
        editable: rmPriceEditable && !sheet.isCltLocked,
        insertable: rmPriceEditable && !sheet.isCltLocked,
        downloadable: true,
        updatable: rmPriceEditable && !sheet.isCltLocked,
        title: sheet.clusterName || "Global",
        rowIdentifier: "id",
        schema: (key: string, sheetEditable?: boolean) => {
          const defaultCell = {
            name: mappingPriceAssumptions[key]
              ? mappingPriceAssumptions[key][0]
              : key,
            width: mappingPriceAssumptions[key]
              ? mappingPriceAssumptions[key][1]
              : 50,
          };
          const options: Record<string, ColumnExtraOptions<IRow>> = {
            id: {
              editable: false,
              frozen: true,
            },
            is_locked: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: "cell-given",
              frozen: true,
              formatter: (props) => {
                const { row, column } = props;
                const cell = row[column.key];
                const value =
                  cell === true || cell?.toString().toLowerCase() === "true"
                    ? true
                    : false;
                return (
                  <SelectCellFormatter
                    tabIndex={-1}
                    value={value}
                    disabled={!column.editable}
                    onChange={() => {
                      props.onRowChange({
                        ...row,
                        [column.key]: !value,
                      });
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    isCellSelected={props.isCellSelected}
                  />
                );
              },
            },
            rcode: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: "cell-given",
              frozen: true,
            },
            raw_material_desc: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: "cell-given",
              frozen: true,
            },
            plant: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: "cell-given",
              frozen: true,
              editor: (props) => {
                const Dropdown = dropdownEditorFactory(
                  key,
                  store.assets.plantSelect.map(({ label }) => ({
                    label,
                    value: label,
                  }))
                );
                return <Dropdown {...props} />;
              },
              editorOptions: {
                editOnClick: true,
              },
            },
            pack_type: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
              frozen: false,
              editor: (props) => {
                const Dropdown = dropdownEditorFactory(key, [
                  { value: "Bulk", label: "Bulk" },
                  { value: "Drum", label: "Drum" },
                ]);
                return <Dropdown {...props} />;
              },
              editorOptions: {
                editOnClick: true,
              },
            },
            supplier: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
            },
            supply_location: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
            },
            inco_term: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
              editor: (props) => {
                const Dropdown = dropdownEditorFactory(key, [
                  { value: "BEW", label: "BEW" },
                  { value: "CFR", label: "CFR" },
                  { value: "CIF", label: "CIF" },
                  { value: "CIP", label: "CIP" },
                  { value: "CCL", label: "CCL" },
                  { value: "CPT", label: "CPT" },
                  { value: "DAP", label: "DAP" },
                  { value: "DDP", label: "DDP" },
                  { value: "DDU", label: "DDU" },
                  { value: "EXW", label: "EXW" },
                  { value: "FCA", label: "FCA" },
                  { value: "FOB", label: "FOB" },
                  { value: "PPD", label: "PPD" },
                ]);
                return <Dropdown {...props} />;
              },
              editorOptions: {
                editOnClick: true,
              },
            },
            comments: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
            },
            currency: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
              editor: function Editor(props) {
                const store = useStore();
                const Dropdown = dropdownEditorFactory(
                  key,
                  store.assets.currencySelect.map(({ code }) => ({
                    label: code,
                    value: code,
                  }))
                );
                return <Dropdown {...props} />;
              },
              editorOptions: {
                editOnClick: true,
              },
            },
            unit_of_measure: {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass: sheetEditable ?? true ? "cell-input" : "cell-readonly",
              editor: function Editor(props) {
                const store = useStore();
                const Dropdown = dropdownEditorFactory(
                  key,
                  store.assets.unitSelect.map(({ label }) => ({
                    value: label,
                    label,
                  }))
                );
                return <Dropdown {...props} />;
              },
              editorOptions: {
                editOnClick: true,
              },
            },
          };
          return (
            options[key] || {
              ...defaultCell,
              editable: sheetEditable ?? true,
              cellClass:
                sheetEditable ?? true
                  ? "cell-input cell-numeric"
                  : "cell-readonly",
              formatter: NumberFormatter,
            }
          );
        },
        hideColumns: () => ["#"], // Newly-generated RMPA sheets no longer have this column.
      },
    ]) || [];

  const tabs: TabList = {
    keyAssumptions: {
      kind: "custom",
      id: "keyAssumptions",
      title: "Key Assumptions",
      type: "sheet-initial",
      render: KeyAssumptionsTab,
    },
    forex: {
      kind: "worksheet",
      id: "forex",
      title: "Forex",
      type: "sheet-initial",
      fileType: "forex",
      csvFiles: forexCsvFiles,
      marginalCsvFiles: [],
      customCSVSheet: ForexCSVSheet,

      renderExtraToolbar() {
        if (editable) {
          return (
            <AutofillForexButton
              file={
                forexCsvFiles[0] === undefined ? undefined : forexCsvFiles[0][0]
              }
            />
          );
        }
        return <></>;
      },

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.defineSheets.list(project.id, undefined, undefined, {
          file_type: "forex",
        });
      },
    },
    requirements: {
      kind: "custom",
      id: "requirements",
      title: "Req. Builder",
      type: "sheet-initial",
      render: RequirementsTab,
    },
    formulation: {
      kind: "custom",
      id: "formulation",
      title: "Formulations",
      type: "sheet-initial",
      render: FormulationsTab,
    },
    costOptions: {
      kind: "worksheet",
      id: "costOptions",
      title: "Cost Sheet (Frame)",
      type: "sheet-initial",
      fileType: "cost_sheet",
      uploadable: false,
      csvFiles:
        sheets.get("cost_sheet")?.map((sheet) => [
          {
            id: `costOptions-${sheet.id}`,
            sheet,
            editable: false,
            downloadable: true,
            rowIdentifier: "costing_option_id",
            hideColumns: () => [
              "costing_option_id",
              "formulation_id",
              "plant_split_link",
              "selected",
              "not_selected",
              "concatenate_formulation",
              "concatenate_manufacturing",
              "concatenate_logistics",
            ],
            schema: schemaCostingSheet,
          },
        ]) || [],
      marginalCsvFiles: [],

      renderExtraToolbar() {
        if (editable) {
          return <GenerateCostSheetButton />;
        }
        return <></>;
      },

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.defineSheets.list(project.id, undefined, undefined, {
          file_type: "cost_sheet",
        });
      },
    },
    deliveryInfo: {
      kind: "custom",
      id: "deliveryInfo",
      title: "Delivery Info",
      type: "sheet-initial",
      render: DeliveryInfoTab,
    },
    priceAssumptions: {
      kind: "worksheet",
      id: "priceAssumptions",
      title: "RM Price Assumptions",
      type: "sheet-initial",
      fileType: "rm_price_assumptions",
      uploadable: async (file) => {
        if (!project) {
          return;
        }
        try {
          await project?.uploadPriceAssumptions(file);
        } catch (e) {
          Toast.danger("Price Assumptions upload failed.");
          return;
        }
        Toast.success("Price Assumptions uploaded successfully.");
      },
      csvFiles: rmpaCsvFiles,
      marginalCsvFiles: [],

      renderExtraToolbar() {
        if (rmPriceEditable) {
          return (
            <AutofillRMPAButton
              file={
                rmpaCsvFiles[0] === undefined ? undefined : rmpaCsvFiles[0][0]
              }
            />
          );
        }
        return <></>;
      },

      async csvLoader() {
        if (!project) {
          return;
        }
        await store.defineSheets.list(project.id, undefined, undefined, {
          file_type: "rm_price_assumptions",
        });
      },
    },
    rawMaterialReport: {
      kind: "custom",
      id: "rawMaterialReport",
      title: "Raw Material Report",
      type: "sheet-initial",
      render: RMFormulationsTab,
    },
  };

  if (!project) {
    return <Loading full />;
  }

  return <WorkArea tabs={tabs} />;
};

export default observer(DefineSection);
