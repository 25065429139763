import base64js from "base64-js";
import base, { APIDetail, APIList, APISearchParams, config } from "./base";
import { APIInputSheetInput } from "./inputSheets";

export type APIOutputSheetFileType =
  | "costing_sheet"
  | "marginal_cost"
  | "formulation_product"
  | "formulation_base_oil"
  | "formulation_additive"
  | "formulation_selection"
  | "formulation_totals"
  | "sourcing_selection"
  | "raw_material_summary";

export interface APIOutputSheet {
  id: identifier;
  project: identifier;
  fileType: APIOutputSheetFileType;
  file: string;
  errorFile: string;
  specs: string;
  viscosity: string;
  countryNames: string[];
  plant: string;
  notes: string;
}

export type APIOutputSheetInput = Partial<
  Omit<
    APIOutputSheet,
    | "id"
    | "project"
    | "fileType"
    | "viscosity"
    | "specs"
    | "plant"
    | "countryNames"
  >
>;

export interface APIOutputSheetList extends APIList<APIOutputSheet> {}

export interface APIOutputSheetSearchParams extends APISearchParams {
  project?: number;
  file_type?: APIOutputSheetFileType;
  file_type__in?: APIOutputSheetFileType[];
}

export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APIOutputSheetSearchParams
) =>
  base.list<APIOutputSheetList, APIOutputSheetSearchParams>(
    "output-sheets/",
    page,
    limit,
    searchParams
  );

export interface APIOutputSheetDetail extends APIDetail<APIOutputSheet> {}

export const update = (
  sheetId: identifier,
  csvData: string,
  data: APIInputSheetInput
) =>
  base.patch<APIOutputSheetDetail>(`output-sheets/${sheetId}/`, {
    json: {
      file: csvData
        ? base64js.fromByteArray(Buffer.from(csvData) as unknown as Uint8Array)
        : undefined,
      ...data,
    },
  });
