import base, { APIList } from "./base";

export interface APIRMPA {
  id: identifier;
  rCode: string;
  productName: string;
  plantName: string;
  vendorName: string;
  supplyLocationCountry: string;
  incoTerm: string;
  currencyCode: string;
  rmPurchasePrice: number;
  supplyFreight: number;
  drummingDifferential: number;
  unitOfMeasure: string;
}
export interface APIRMPAList extends APIList<APIRMPA> {}

type RMPADataFilter = {
  r_code: string;
  product_name: string;
  plant: string;
  bulk_or_drum: string;
};

export interface APIRMPAListSearchParams {
  project_type: string;
  costing_date: string;
  rmpa_data: RMPADataFilter[];
}

export const list = (data: APIRMPAListSearchParams) =>
  base.post<APIRMPAList>("purchase-price/search/", { json: data });
