import api from "api";
import { APIInputSheetDetail, APIInputSheetFileType } from "api/inputSheets";
import {
  _async,
  _await,
  ExtendedModel,
  getRootStore,
  model,
  modelFlow,
  prop,
} from "mobx-keystone";
import { RootStore } from "..";
import Sheet, { SheetType } from "./Sheet";

@model("collab/InputSheet")
class InputSheet extends ExtendedModel(Sheet, {
  cluster: prop<identifier>(),
  clusterName: prop<string>(),
  fileType: prop<APIInputSheetFileType>(),
  fileEu: prop<string>(),
  isCltLocked: prop<boolean>(),
  isCltApproved: prop<boolean>(),
  isCltSubmitted: prop<boolean>(),
  isEditable: prop<boolean>(),
}) {
  get sheetType(): SheetType {
    return "inputsheet";
  }

  @modelFlow
  fetchUpdate = _async(function* (this: InputSheet) {
    let response: APIInputSheetDetail | undefined;
    try {
      response = yield* _await(api.inputSheets.get(this.id));
    } catch (e) {
      console.error(e);
      return;
    }
    this.update(response);
  });

  @modelFlow
  save = _async(function* (this: InputSheet, manual: boolean) {
    const rootStore = getRootStore<RootStore>(this);

    if (!rootStore) {
      return false;
    }

    if (!manual && !this.isDirty) {
      return false;
    }

    if (!this.file) {
      return false;
    }

    let response: APIInputSheetDetail | undefined;
    try {
      if (this.csvString) {
        response = yield* _await(
          api.inputSheets.update(this.id, this.csvString)
        );
      } else {
        response = yield* _await(api.inputSheets.update(this.id));
      }
    } catch (e) {
      throw e;
    }
    this.update(response);

    return true;
  });

  // BUG: For some reason when `autofill` is defined on the model,
  // sheets that are not of type `InputSheet` are not loaded.
  // @modelFlow
  // autofill = _async(function* (this: InputSheet) {
  //   const rootStore = getRootStore<RootStore>(this);

  //   if (!rootStore) {
  //     return false;
  //   }

  //   if (!this.file) {
  //     return false;
  //   }

  //   let response: APIInputSheetDetail | undefined;
  //   try {
  //     response = yield* _await(api.inputSheets.autofill(this.id));
  //   } catch (e) {
  //     throw e;
  //   }
  //   this.update(response);

  //   return true;
  // });
}

export default InputSheet;
